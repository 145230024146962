/*------------------------------------------------------------------
[Table of contents]

1. General Styles
2. Navbar
3. Home
4. About
5. Skills
6. Portfolio
7. Contact / Footer
8. Media Queries

[Page Colors]

"White": #F5F5F5 (Cultured)
"Black": #331F2B (Dark Purple)
Gray:    #858585 (Battleship Grey)
Purple:  #690C97 (Purple)
-------------------------------------------------------------------*/


/* 1. General Styles */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=DM+Serif+Display&family=East+Sea+Dokdo&display=swap');

body {
  height: 100%;
  position: relative;
  font-family: 'DM Sans', sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
h1 {
  font-family: 'East Sea Dokdo', cursive;
  font-size: 5em !important;
  font-weight: normal !important;
}
h2 {
  font-family: 'DM Serif Display', serif;
  font-weight: normal !important;
}
h3 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
}
.opacity-min {
  opacity:0.75
}
.display-middle {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  -ms-transform:translate(-50%,-50%)
}
.display-topmiddle {
  position:absolute;
  left:50%;
  top:0;
  transform:translate(-50%,0%);
  -ms-transform:translate(-50%,0%)
}

/* 2. Navbar */
.navbar {
  background-color: #F5F5F5;
  color: #331F2B !important;
}
.navbar-logo {
  max-width: 40px;
  vertical-align: middle;
  margin: 0 !important;
  padding: 0 !important;
  filter: invert(7%) sepia(91%) saturate(6954%) hue-rotate(282deg) brightness(92%) contrast(97%);
}
.navbar-logo:hover {
  filter: invert(63%) sepia(0%) saturate(1%) hue-rotate(200deg) brightness(83%) contrast(85%);
}
.brand {
  font-family: 'East Sea Dokdo', cursive;
  font-size: 1.5rem !important;
  color: #858585 !important;
}
.navlink {
  font-family: 'DM Serif Display', serif;
  letter-spacing: 0.05rem;
}
a.navlink:link,a.navlink:visited,a.navlink:active {
  color: #690C97 !important;
}
a.navlink:hover {
  color: #858585 !important;
}

/* 3. Home */
.composition-book {
  letter-spacing: 0.5rem;
  font-family: 'DM Serif Display', serif;
  font-size: 0.75rem;
  color: #858585;
  display: inline;
  white-space: nowrap;
}
.composition-line {
  border: 1px dotted #858585 !important;
}
.home-img {
  background-image: url("img/composition-book.png") !important;
}
.sticker-container {
  background-color: #F5F5F5;
  padding: 4em;
  border-style: solid;
  border-color: #331F2B;
  border-radius: 25px;
}

/* 4. About */
a.link:link,a.link:visited,a.link:active {
  color: #690C97 !important;
}
a.link:hover {
  color: #858585 !important;
}
.about-h2 {
  font-size: 2.5em !important;
}
.school-img {
  max-width: 60px !important;
}
.edu {
  font-size: 1.25em !important;
}
.cert-name{
  font-family: 'DM Serif Display', serif;
  font-weight: lighter !important;
  margin-bottom: 0 !important;
}

/* 5. Skills */
.skills {
  background-image: url("img/lined-paper.png");
  background-color: #F5F5F5 !important;
}
.skills-img {
  max-width: 40px !important;
}

/* 6. Portfolio */
.portfolio-header {
  background-image: url("img/Stickers.png");
  min-height: 300px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.label-maker{
  font-family: monospace, sans-serif;
  border: 1px solid #999;
  border-bottom-color: #331F2B;
  padding: .5em;
  display: inline-block;
  color: #F5F5F5;
  font-size: 2em !important;
  letter-spacing: .5em;
  text-shadow:
      /*inner*/ 0 0 1px #331F2B,
      /*top*/   0 -2px 1px #aaa,
      /*right*/ 2px 0 1px #aaa,
      /*botom*/ 0 2px 0 #666,
      /*left*/ -2px 0 1px #aaa;
  background-color: #333;
  background-image: linear-gradient(#444, #333);
  box-shadow: 0 1px 1px #ccc;
}
.portfolio-img {
  max-height: 150px !important;
}

/* 7. Footer */
.footer {
  background-color: #806c51;
  background-image: url("img/cardboard.png");
}
.fit-cover {
  object-fit: cover;
}
.link-light {
  background-color: #690c97 !important;
}
a.link-light:hover {
  color: #F5F5F5 !important;
  background-color: #331F2B !important;
}
.footer-logo {
	max-width: 7em;
	filter: invert(92%) sepia(75%) saturate(3482%) hue-rotate(187deg) brightness(127%) contrast(92%);
}

/* 8. Media Queries */
@media only screen and (max-width: 768px) {
  h1 {font-size: 3.75em !important};
  .about-h2 {font-size: 1em !important;}

}
@media only screen and (max-width: 600px) {
  .sticker-container {
    padding-top: 3em !important;
    padding-bottom: 1em !important;
  }
  h1 {
    line-height: 80% !important;
  }
  .title-h2 {
    font-size: 1.25em !important;
  }
  .about-h2 {
    font-size: 1.75em !important;
  }
  .portfolio-header {
    background-attachment: scroll;
    min-height: 300px;
  }
  .label-maker {
    font-size: 1.75em !important;
  }

}